
export const Status = [

    {
        option: "Opened",
        optionvalue: "Opened",
    },
    {
        option: "Closed",
        optionvalue: "Closed",
    },
    {
        option: "Temporary Closed",
        optionvalue: "Temporary Closed",
    },
    {
        option: "Cancelled",
        optionvalue: "Cancelled",
    },
    {
        option: "Billed",
        optionvalue: "Billed",
    },

    {
        option: "pending",
        optionvalue: "pending",
    },
    {
        option: "All",
        optionvalue: "All",
    },

]