import React, { useState, useRef, useEffect } from "react";
import './RefPdfParticularData.css'
import generatePDF from 'react-to-pdf';
import useGroupbilling from "./useGroupbilling";
// import { APIURL } from "../../../url";
import numWords from 'num-words'
import { useData } from "../../../Dashboard/MainDash/Sildebar/DataContext2";
import { green } from "@mui/material/colors";

const RefPdfParticularData = ({ pdfData = [], organizationdetails = [], imagename, refFromDate, refToDate, gstno, referenceno }) => {

    const { handlePopup } = useGroupbilling()
    const targetRef = useRef()
    const [orgname, setOrgname] = useState('')
    const [orgaddress1, setOrgaddress1] = useState('')
    // const [orgaddress2, setOrgaddress2] = useState('')
    const [orgaddress3, setOrgaddress3] = useState('')
    const [customerAddress, setCustomerAddress] = useState('')
    const [customer, setCustomer] = useState('')
    const [gst, setGst] = useState('')
    const [fullAmount, setFullAmount] = useState('')
    const [totalCgst, setTotalCgst] = useState(0)
    const [advance, setAdvance] = useState();
    const [fullTotal, setFullTotal] = useState(0)
    // const apiUrl = APIURL;
    // const organisationimage = imagename
    const FromDate = refFromDate
    const ToDate = refToDate
    const refno = referenceno

    useEffect(() => {
        if (Array.isArray(gstno) && gstno.length > 0) { // Check if gstno is an array and not empty
            let gstNo = "";
            gstno.forEach((li) => {
                gstNo = li.gstTax;
            });
            setGst(gstNo);
        }
    }, [gstno]); // Add gstno as a dependency

    const Gst = gst / 2;

    useEffect(() => {
        let address = ""
        //  let address1 = ""
        //  let city = ""
        let customer = ""
        let totalamount = 0
        let totalcgst = 0
        let advanceamount = 0
        let fullamount = 0

        if (Array.isArray(pdfData)) {
            pdfData.forEach((li) => {
                address = li.address1
                customer = li.customer
                totalamount += parseInt(li.totalcalcAmount)
                totalcgst += parseInt(li.totalcalcAmount) * Gst / 100
                advanceamount += parseInt(li.customeradvance || 0)
                fullamount += parseInt(li.totalcalcAmount) + parseInt(li.totalcalcAmount) * Gst / 100 + parseInt(li.totalcalcAmount) * Gst / 100 - (parseInt(li.customeradvance) || 0)
            })
        }

        setCustomerAddress(address)
        setCustomer(customer)
        setFullAmount(totalamount.toFixed(0))
        setTotalCgst(totalcgst.toFixed(0))
        setAdvance(advanceamount)
        setFullTotal(fullamount.toFixed(0))
    }, [pdfData, Gst])

    useEffect(() => {
        let addressone = ''
        // let addresstwo = ''
        let addressthree = ''
        let organisationname = ''

        if (Array.isArray(organizationdetails)) {
            organizationdetails.forEach((li) => {
                addressone = li.addressLine1
                addressthree = li.location
                organisationname = li.organizationname
            })
        }

        setOrgaddress1(addressone)
        setOrgaddress3(addressthree)
        setOrgname(organisationname)
    }, [organizationdetails])

    const { logo } = useData()
    // Convert number to words
    const convertToWords = (num) => {
        if (!num) return '';
        const [integerPart, decimalPart] = num.toString().split('.');
        let words = numWords(parseInt(integerPart));
        if (decimalPart) {
            words += ' point';
            for (let digit of decimalPart) {
                words += ` ${numWords(parseInt(digit))}`;
            }
        }
        return words;
    };

    const rupeestext = convertToWords(fullTotal);

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column', width: '784px', padding: 20 }} ref={targetRef}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '50px' }}>
                    <div>
                        <h2 className="organisationnametext" style={{ textTransform: 'uppercase' }}>{orgname}</h2>
                        <h2 className="organisationtext">{customerAddress}</h2>
                    </div>
                    <div className="Taxinvoicediv">
                        <h3 className="Taxinvoicetext">
                            <span> </span>
                            <span className="invoice"> </span>
                        </h3>
                    </div>
                    <div className="imagediv">
                        {/* <img src={`${apiUrl}/public/org_logo/${organisationimage}`} className="image" alt="organisationimage" /> */}
                        <img src={logo} className="image" alt="organisationimage" />
                    </div>
                </div>

                <div className="mobilediv">
                    <h2 className="organisationtext">Tel : {organizationdetails[0]?.telephone} Mob : {organizationdetails[0]?.contactPhoneNumber}</h2>
                    <h2 className="organisationtext">GST : {organizationdetails[0]?.gstnumber}</h2>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '50px', borderBottom: '1px solid grey', paddingBottom: 5 }}>
                    <div>
                        <h2 className="organisationnametext" style={{ textTransform: 'uppercase' }}>{customer}</h2>
                        <h2 className="organisationtext">{orgaddress1}</h2>
                        <h2 className="organisationtext">{orgaddress3}</h2>
                    </div>
                    <div className="Taxinvoicediv">
                        <h3 className="Refnotext">
                            <span>Ref No: </span>
                            <span className="invoice">{refno}</span>
                        </h3>
                    </div>
                </div>

                <div className="Datediv">
                    <p>From: <span className="Datetext">{FromDate}</span></p>
                    <p>To: <span className="Datetext">{ToDate}</span></p>
                </div>

                <div>
                    <table className="table-ref">
                        <thead>
                            <tr>
                                <th className="tableheadtext">SNo</th>
                                <th className="tableheadtext">Bill No</th>
                                <th className="tableheadtext">Bill Date</th>
                                <th className="tableheadtext">Ordered By</th>
                                <th className="tableheadtext">Reported To</th>
                                <th className="tableheadtext">Amount</th>
                                <th className="tableheadtext">CGST</th>
                                <th className="tableheadtext">SGST</th>
                                {pdfData.some(li => parseInt(li.customeradvance) > 0) && (
                                    <th className="tableheadtext">Cus Adv</th>
                                )}
                                <th className="tableheadtext">Bill Amt</th>
                            </tr>
                        </thead>
                        <tbody className="tablebody" style={{ height: pdfData.length <= 2 ? '180px' : '100%' }}>
                            {pdfData.map((li, index) => (
                                <tr key={index} className="tabledata-ref">
                                    <td className="tdata">{index + 1}</td>
                                    <td className="tdata">{li.InvoiceNo}</td>
                                    <td className="tdata">{li.InvoiceDate}</td>
                                    <td className="tdata">{li.customer}</td>
                                    <td className="tdata">{li.guestname}</td>
                                    <td className="tdata">{li.totalcalcAmount}</td>
                                    <td className="tdata">{(parseInt(li.totalcalcAmount) * Gst / 100).toFixed(0)}</td>
                                    <td className="tdata">{(parseInt(li.totalcalcAmount) * Gst / 100).toFixed(0)}</td>
                                    {parseInt(li.customeradvance) > 0 && (
                                        <td className="tdata">{parseInt(li.customeradvance)}</td>
                                    )}
                                    <td className="tdata">
                                        {(parseInt(li.totalcalcAmount) + parseInt(li.totalcalcAmount) * Gst / 100 + parseInt(li.totalcalcAmount) * Gst / 100).toFixed(0) - parseInt(li.customeradvance || 0)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td className="tdata">{ }</td>
                                <td className="tdata"> </td>
                                <td className="tdata"></td>
                                <td className="tdata"></td>
                                <td className="tdata">Total</td>
                                <td className="tdata">{fullAmount}</td>
                                <td className="tdata">{totalCgst}</td>
                                <td className="tdata">{totalCgst}</td>
                                {advance > 0 && (
                                    <td className="tdata">{advance}</td>
                                )}
                                <td className="tdata">{fullTotal}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingTop: '10px', textTransform: 'capitalize' }}><h4 style={{ margin: 0 }}>Rs.</h4><p style={{ marginLeft: 6, marginTop: '0px', fontWeight: 600 }}>{rupeestext}</p></div>
                {gst === 0 ?
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ fontWeight: 600, marginRight: '5px' }}>NOTE:</h4>
                        <h4 style={{ padding: 2, wordSpacing: 3 }}>
                            IGST@5% or both CGST@2.5% & SGST@2.5% of Rs:335 is to be paid by Service Recepient Under RCM as per Notification 22/2019 – Central tax (Rate) dated 30-09-2019
                        </h4>
                    </div> : ""
                }

            </div>

            <div className="printdiv">
                <button className="print" onClick={() => generatePDF(targetRef, { filename: `${refno}.pdf` })}>Print</button>
                <button onClick={handlePopup} className="print">
                    Cancel
                </button>
            </div>

        </>
    )
}

export default RefPdfParticularData
