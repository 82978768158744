// Pay Type Dropdown
export const PayType = [
  {
    Option: "Cash",
    optionvalue: "cash",
  },
  {
    Option: "BTC",
    optionvalue: "btc",
  },
  {
    Option: "Direct",
    optionvalue: "driect",
  },
  {
    Option: "Credit",
    optionvalue: "credit",
  },
];

// Duty Dropdown
export const Duty = [
  {
    Option: "Local",
    optionvalue: "local",
  },
  {
    Option: "OneWay",
    optionvalue: "oneway",
  },
  {
    Option: "Outstation",
    optionvalue: "Outstation",
  },
  {
    Option: "Transfer",
    optionvalue: "transfer",
  },
];

// hire type
export const Hire = [
  {
    Option: "Attached Vehicle",
    optionvalue: "attachedvehicle",
  },
  {
    Option: "Out Side Travels",
    optionvalue: "outsidetravels",
  },
  {
    Option: "Own  Vehicle",
    optionvalue: "ownvehicle",
  },

];

export const GroupTypes = [
  {
    Option: "Luxury",
  },
  {
    Option: "Normal",
  },
  {
    Option: "Premium",
  },
  {
    Option: "Non-Premium",
  },
];

export const vehicaleinfos = [
  {
    Option: "A/C",
  },
  {
    Option: "Non A/C",
  },
];